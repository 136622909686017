<template>
  <div class="approximate-date-field flex-row">
    <div>
      <div class="input-label" v-if="showLabels">Day</div>
      <multiselect
        class="day-multiselect bordered"
        ref="select-day"
        :value="dayValue"
        :searchable="true"
        :internal-search="true"
        :preserveSearch="false"
        :clearOnSelect="false"
        :hide-selected="false"
        :options="dayOptions"
        track-by="value"
        label="label"
        selectLabel=""
        selectedLabel=""
        deselectLabel=""
        placeholder="Day"
        @select="changeDay"
        @search-change="onDaySearchChange"
      >
        <span slot="noResult" class="input-helper">Not Found</span>
      </multiselect>
    </div>

    <div>
      <div class="input-label" v-if="showLabels">Month</div>
      <multiselect
        class="month-multiselect bordered"
        ref="select-month"
        :value="monthValue"
        :searchable="true"
        :internal-search="true"
        :preserveSearch="false"
        :clearOnSelect="false"
        :hide-selected="false"
        :options="monthOptions"
        track-by="value"
        label="label"
        selectLabel=""
        selectedLabel=""
        deselectLabel=""
        placeholder="Month"
        @select="changeMonth"
        @search-change="onMonthSearchChange"
      >
        <span slot="noResult" class="input-helper">Not Found</span>
      </multiselect>
    </div>

    <div>
      <div class="input-label" v-if="showLabels">Year</div>
      <input
        :value="year"
        type="text"
        placeholder="YYYY"
        maxlength="4"
        size="4"
        class="input"
        @input="changeYear"
        @blur="onYearBlur"
      />
    </div>
    <div class="approximation-container" v-if="showApproximation">
      <div class="input-label" v-if="showLabels">{{ plusMinus }} Years</div>
      <input :value="approximationYears" class="input" maxlength="4" size="4" @input="changeApproximationYears" />
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  props: {
    year: [Number, String],
    month: [Number, String],
    day: [Number, String],
    approximationYears: [Number, String],
    approximationType: [Number],
    showApproximation: {type: Boolean, default: true},
    showLabels: {type: Boolean, default: true},
  },
  computed: {
    dayOptions() {
      const days = [...Array(31).keys()].map(i => {
        return {value: i + 1, label: i + 1};
      });
      return [{value: null, label: 'DD'}, ...days];
    },
    dayValue() {
      return this.dayOptions.find(option => option.value === (this.day || null));
    },
    monthOptions() {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map(
        (month, i) => {
          return {value: i + 1, label: `${i + 1} - ${month}`};
        }
      );
      return [{value: null, label: 'MM'}, ...months];
    },
    monthValue() {
      return this.monthOptions.find(option => option.value === (this.month || null));
    },
    plusMinus() {
      switch (this.approximationType) {
        case 1:
          return '-';
        case 2:
          return '+';
        default:
          return '+/-';
      }
    },
  },
  methods: {
    changeDay(event) {
      this.$emit('change-day', event.value);
    },
    changeMonth(event) {
      this.$emit('change-month', event.value);
    },
    changeYear(event) {
      this.$emit('change-year', event.target.value);
    },
    onYearBlur() {
      const year = parseInt(this.year) || null;
      this.$emit('change-year', year);
    },
    changeApproximationYears(event) {
      this.$emit('change-approximation-years', event.target.value);
    },
    onDaySearchChange() {
      const options = this.$refs['select-day'].filteredOptions;
      if (this.$refs['select-day'].isOpen && options && options.length) {
        this.changeDay(options[0]);
      }
    },
    onMonthSearchChange() {
      const options = this.$refs['select-month'].filteredOptions;
      if (this.$refs['select-month'].isOpen && options && options.length) {
        this.changeMonth(options[0]);
      }
    },
  },
  components: {Multiselect},
};
</script>

<style lang="scss" scoped>
.approximate-date-field {
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 12px;

  .day-multiselect {
    width: 80px;
  }
  .month-multiselect {
    width: 115px;
  }
}
</style>
