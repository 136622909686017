<template>
  <transition mode="out-in" name="info-bar-slide-bottom">
    <div v-if="showViewStep" key="stepview" class="person-quick-view" v-touch:swipe.bottom="onSwipeBottom">
      <div class="heading">
        <div class="picture-container">
          <img
            :src="profilePictureAttachment"
            v-if="profilePictureAttachment"
            class="profile-picture"
            @click="profilePictureClick"
          />
          <div class="loading-profile-picture" v-else-if="showProfilePictureLoading"></div>
          <person-icon-colored v-else :gender="person.gender" @click.native="personIconClick"></person-icon-colored>
        </div>
        <div class="name-container">
          <div class="heading-4 heading-5-mobile" v-if="personPrimaryFullnameEn">{{ personPrimaryFullnameEn }}</div>
          <div class="heading-4 heading-5-mobile" v-if="personPrimaryFullnameCn">{{ personPrimaryFullnameCn }}</div>
          <div class="heading-4 heading-5-mobile" v-if="!personPrimaryFullnameEn && !personPrimaryFullnameCn">
            {{ unknownName }}
          </div>
          <div class="strapline" v-if="livingStatus">{{ livingStatus }}</div>
        </div>
        <div class="birth-order" v-if="birthOrder">
          <mcr-wiki-glossary-link :is-help-text="true" naturalId="sibling-order" :text="`#${birthOrder}`" />
        </div>
        <close-icon @click="$emit('close')"></close-icon>
      </div>
      <div class="details">
        <div class="section">
          <person-photos-container
            ref="photos"
            :add-allowed="addPhotosAllowed"
            :person-id="person.id"
            :person-name-en="personPrimaryFullnameEn"
            :person-name-cn="personPrimaryFullnameCn"
            :explicit-loading="showExplicitPhotosLoading"
            :display-photos-count-mobile="3"
            @files-selected="filesSelected"
            @file-uploaded="fileUploaded"
          >
            <div class="label no-margin" slot="title">Photos</div>
          </person-photos-container>
        </div>

        <div class="section spacing"></div>

        <div class="person-quick-view-actions text-md" v-touch:swipe.bottom.stop>
          <div
            class="action hints"
            @click="
              $emit('close');
              trackClick('Review Hints');
              showHintsHandler();
            "
            v-if="hintsCount"
          >
            <div class="hints-icon"><img :src="hintsIconSrc" /></div>
            <div>{{ hintsLabel }}</div>
          </div>
          <div
            class="action"
            @click="
              $emit('close');
              trackClick('Full Profile');
              fullProfileHandler();
            "
          >
            <profile-icon></profile-icon>
            <div v-tooltip="fullProfileTooltip">Full Profile</div>
          </div>
          <div
            class="action"
            v-if="editable"
            @click="
              $emit('close');
              trackClick('Quick Edit');
              quickEditHandler();
            "
          >
            <pencil-icon></pencil-icon>
            <div>Quick Edit</div>
            <arrow-icon class="arrow-icon" :size="24" />
          </div>
          <div class="action" :class="{disabled: !searchByProfileEnabled}" @click="onSearchByProfileClick">
            <magnify-icon></magnify-icon>
            <div>Search</div>
            <arrow-icon class="arrow-icon" :size="24" />
          </div>
          <div
            class="action"
            v-if="editableFull"
            @click="
              $emit('close');
              trackClick('Full Edit');
              fullProfileEditHandler();
            "
          >
            <account-edit-icon></account-edit-icon>
            <div>Full Edit</div>
          </div>
          <div class="action" v-if="showAddRelative" @click="onAddRelativeClick">
            <plus-icon></plus-icon>
            <div v-tooltip="addRelativeTooltip">Add Relative</div>
            <arrow-icon class="arrow-icon" :size="24" />
          </div>
          <div
            class="action"
            @click="
              trackClick('More Actions');
              openMoreActionsModal();
            "
          >
            <dots-icon :size="22"></dots-icon>
            <div>More Actions</div>
            <arrow-icon class="arrow-icon" :size="24" />
          </div>
        </div>
      </div>
    </div>
    <quick-more-actions-modal
      v-else-if="showMoreStep"
      key="stepmore"
      :person-name="primaryName"
      :show-view-tree="showViewTree"
      :show-view-lineage="showViewLineage"
      :removable="removable"
      :view-tree-handler="viewTreeHandler"
      :view-lineage-handler="viewLineageHandler"
      :view-relationship-handler="viewRelationshipHandler"
      :delete-handler="deleteHandler"
      @close="$emit('close')"
    ></quick-more-actions-modal>
    <quick-add-relative-modal
      v-else-if="showRelativeStep"
      key="steprelative"
      :person-name="primaryName"
      :person-id="person.object_id"
      :add-parent-handler="addParentHandler"
      :add-spouse-handler="addSpouseHandler"
      :add-sibling-handler="addSiblingHandler"
      :add-child-handler="addChildHandler"
      @close="$emit('close')"
    ></quick-add-relative-modal>
  </transition>
</template>

<script>
import McrWikiGlossaryLink from '@common/elements/glossary/mcrWikiGlossaryLink';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getLifetimeYears, isChineseText} from '@common/utils/utils';
import {getFullNameCn, getFullNameEn} from '@common/utils/utils.names';
import ProfileIcon from 'vue-material-design-icons/Account';
import AccountEditIcon from 'vue-material-design-icons/AccountEdit';
import ArrowIcon from 'vue-material-design-icons/ChevronRight';
import CloseIcon from 'vue-material-design-icons/Close';
import DotsIcon from 'vue-material-design-icons/DotsHorizontalCircleOutline';
import MagnifyIcon from 'vue-material-design-icons/Magnify';
import PencilIcon from 'vue-material-design-icons/Pencil';
import PlusIcon from 'vue-material-design-icons/Plus';
import {mapGetters} from 'vuex';

import PersonIconColored from '@/base/icons/person/PersonIconColored';

import {
  CARD_ADD_RELATIVE_HELPER_KEY,
  CARD_FULL_PROFILE_HELPER_KEY,
  CONTEXT_MENU_SECTIONS,
  UNKNOWN_NAME,
} from '@/components/modules/familyTree/constants';
import PersonPhotosContainer from '@/components/modules/familyTree/tree/modals/personPhotosContainer';
import QuickAddRelativeModal from '@/components/modules/familyTree/tree/modals/quick/QuickAddRelativeModal';
import QuickMoreActionsModal from '@/components/modules/familyTree/tree/modals/quick/QuickMoreActionsModal';

import personQuickViewMixin from './personQuickViewMixin';

const STEP_VIEW = 'stepview';
const STEP_MORE = 'stepmore';
const STEP_RELATIVE = 'steprelative';

export default {
  mixins: [personQuickViewMixin],
  props: {
    person: Object,
    addPhotosAllowed: Boolean,
    showViewTree: Boolean,
    showViewLineage: Boolean,
    removable: Boolean,
    contextMenuSections: Array,
    hintsCount: Number,
    searchByProfileEnabled: Boolean,
    fullProfileHandler: Function,
    searchByProfileHandler: Function,
    fullProfileEditHandler: Function,
    fullProfileEditWithFocusHandler: Function,
    quickEditHandler: Function,
    viewTreeHandler: Function,
    viewLineageHandler: Function,
    viewRelationshipHandler: Function,
    deleteHandler: Function,
    addParentHandler: Function,
    addSpouseHandler: Function,
    addSiblingHandler: Function,
    addChildHandler: Function,
    showHintsHandler: Function,
    birthOrder: {Number, optional: true},
  },
  data() {
    return {
      shownModalViewStep: STEP_VIEW,
    };
  },
  computed: {
    ...mapGetters(['familyTreePersonsByIdState', 'reviewedItemsState']),
    livingStatus() {
      return getLifetimeYears(this.person.birth_date, this.person.death_date, this.person.is_deceased);
    },
    profilePicture() {
      const person = this.familyTreePersonsByIdState[this.person.id];
      return person && person.profile_picture && person.profile_picture;
    },
    profilePictureAttachment() {
      return this.profilePicture && this.profilePicture.attachment;
    },
    primaryName() {
      return this.personPrimaryFullnameEn || this.personPrimaryFullnameCn || UNKNOWN_NAME;
    },
    personPrimaryFullnameEn() {
      return getFullNameEn(this.person.first_names || [], this.person.surnames || []);
    },
    personPrimaryFullnameCn() {
      return getFullNameCn(this.person.first_names || [], this.person.surnames || []);
    },
    unknownName() {
      return this.person.is_current_user ? 'You' : UNKNOWN_NAME;
    },
    showExplicitPhotosLoading() {
      return Boolean(this.profilePictureAttachment);
    },
    showViewStep() {
      return this.shownModalViewStep === STEP_VIEW;
    },
    showMoreStep() {
      return this.shownModalViewStep === STEP_MORE;
    },
    showRelativeStep() {
      return this.shownModalViewStep === STEP_RELATIVE;
    },
    editable() {
      return this.contextMenuSections.includes(CONTEXT_MENU_SECTIONS.EDIT);
    },
    editableFull() {
      return this.contextMenuSections.includes(CONTEXT_MENU_SECTIONS.FULL_PROFILE_EDIT);
    },
    showAddRelative() {
      return this.contextMenuSections.includes(CONTEXT_MENU_SECTIONS.ADD_RELATIVE);
    },
    hintsLabel() {
      return `Review ${this.hintsCount} Record Match${this.hintsCount > 1 ? 'es' : ''}`;
    },
    hintsIconSrc() {
      return this.$getAsset('/assets/mcr_logo_dude_transparent.png');
    },
    fullProfileTooltip() {
      return {
        popperClass: 'text-center horizontal-bounce',
        theme: 'tooltip-onboarding',
        placement: 'right',
        triggers: [],
        container: false,
        shown: this.$store.getters.showCardFullProfileTooltipTooltip,
        distance: 20,
        skidding: -2,
        delay: {show: 200},
        content: 'Click for more details',
      };
    },
    addRelativeTooltip() {
      return {
        popperClass: 'text-center horizontal-bounce',
        theme: 'tooltip-onboarding',
        placement: 'right',
        triggers: [],
        container: false,
        shown: this.$store.getters.showCardAddRelativeTooltipState,
        distance: 20,
        skidding: -2,
        delay: {show: 200},
        content: 'Add a relative',
      };
    },
  },
  methods: {
    isChineseText,
    openMoreActionsModal() {
      this.shownModalViewStep = STEP_MORE;
    },
    openAddRelativeModal() {
      this.shownModalViewStep = STEP_RELATIVE;
    },
    onSwipeBottom() {
      this.$emit('close');
    },
    trackClick(label) {
      AnalyticsMainHandler.trackTreeQuickMenuActionClickEvent(label, null);
    },
    onAddRelativeClick() {
      this.$store.commit('setShowCardAddRelativeTooltipState', false);
      this.$store.commit('addReviewedItemState', CARD_ADD_RELATIVE_HELPER_KEY);
      if (!this.$store.getters.reviewedItemsState.includes(CARD_FULL_PROFILE_HELPER_KEY)) {
        this.$store.commit('setShowCardFullProfileTooltipState', true);
      }
      this.trackClick('Add Relative');
      this.openAddRelativeModal();
    },
    onSearchByProfileClick() {
      if (this.searchByProfileEnabled) {
        this.$emit('close');
        this.trackClick('Search');
        this.searchByProfileHandler();
      }
    },
  },
  components: {
    McrWikiGlossaryLink,
    DotsIcon,
    AccountEditIcon,
    ArrowIcon,
    ProfileIcon,
    PencilIcon,
    PlusIcon,
    MagnifyIcon,
    PersonPhotosContainer,
    CloseIcon,
    PersonIconColored,
    QuickAddRelativeModal,
    QuickMoreActionsModal,
  },
  name: 'PersonQuickView',
};
</script>

<style lang="scss" scoped>
.person-quick-view {
  padding: 0;
  min-height: 100%;
  box-sizing: border-box;
  position: relative;

  .heading {
    display: flex;
    padding: 16px 16px 0;
    border-bottom: 0;
    .strapline {
      margin: 8px 0 0;
      color: $neutral-500;
    }

    .name-container {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      word-break: break-word;
      h4 {
        margin: 0;
      }
    }
    .birth-order {
      margin: 4px 4px 0;
      color: $neutral-600;
    }
    .close-icon {
      margin-left: auto;
      top: 0;
      right: 0;
      position: relative;
    }
  }

  .person-photos-container {
    border-top: 1px solid $neutral-200;
    padding: 16px;
    margin-top: 16px;

    &::v-deep {
      .heading {
        padding: 0;
        border-bottom: none;

        .add-button {
          color: $neutral-500;
        }
      }
      .photos {
        margin-top: 8px;
      }
      .view-all-link {
        margin-top: 12px;
        a {
          color: $neutral-500;
        }
      }
      .image-preview-gallery {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  .label {
    color: $neutral-600;
  }

  .section {
    border-bottom: 1px solid $neutral-200;
    margin-bottom: 0;
  }
  .section.spacing {
    height: 16px;
    background: $neutral-100;
  }

  .picture-container {
    width: 58px;
    height: 58px;
    flex-shrink: 0;
    cursor: pointer;
    border-radius: 4px;
    overflow: hidden;

    &::v-deep .material-design-icon {
      width: 40px;
    }
  }
  .loading-profile-picture {
    width: 100%;
    height: 100%;
    background-color: $photo-border-color;
    border-radius: 4px;
    margin-bottom: 5px;
  }
  .person-quick-view-actions {
    .action {
      display: flex;
      align-items: center;
      padding: 14px 16px;
      border-bottom: 1px solid $neutral-200;

      .material-design-icon.arrow-icon {
        margin: 0 0 0 auto;
        color: $neutral-400;
      }

      .material-design-icon {
        color: $icon-grey;
        margin-right: 20px;
        margin-top: -2px;
      }
    }
    .action.hints {
      font-weight: bold;
      color: $mcr-red;

      .hints-icon {
        background: $power-red;
        border-radius: 50%;
        margin-right: 20px;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          margin-left: 1px;
          height: 23px;
        }
      }
    }
    .action.disabled {
      color: $neutral-300;
      .material-design-icon {
        color: $neutral-300;
      }
    }
  }

  @media only screen and (max-width: $breakpoint-mobile-se) {
    .person-quick-view-actions {
      max-height: 300px;
      overflow-y: auto;
    }
  }
}
</style>
